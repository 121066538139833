<template>
  <div class="px-6 pb-6">
    <van-collapse v-model="activeNames" class="az-collapse">
      <!-- About Section -->
      <van-collapse-item name="1">
        <template #title>
          <span class="text-sm font-600">About</span>
        </template>
        <ul class="space-y-1">
          <li v-for="link in aboutLinks" :key="link.text">
            <NuxtLink :to="link.url" class="text-gray-700 hover:text-gray-900">
              {{ link.text }}
            </NuxtLink>
          </li>
        </ul>
      </van-collapse-item>
      <van-collapse-item name="2">
        <!-- Contact Section -->
        <template #title>
          <span class="text-sm font-600">Contact us</span>
        </template>
        <ul class="mb-5">
          <li>
            <NuxtLink to="mailto:info@azgotrip.com" target="_self" class="text-gray-700 hover:text-gray-900">
              Email: info@azgotrip.com
            </NuxtLink>
          </li>
          <li>
            <NuxtLink to="tel:+65 62322417" auto-recognition="true" class="text-gray-700 hover:text-gray-900">
              Tel: +65 62322417
            </NuxtLink>
          </li>
        </ul>
        <!-- Address Section -->
        <div class="mb-2 text-left text-sm text-gray-700 font-300">
          <div class="mb-1 font-300">
            Address
          </div>
          <div class="space-y-1">
            <div v-for="address in addresses" :key="address.city">
              <h3 class="text-xs font-300">
                {{ address.city }}
              </h3>
              <p class="text-xs font-300" v-html="address.details"></p>
            </div>
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>

    <!-- Footer Section -->
    <footer class="mb-4 mt-8 text-center text-sm text-gray-600">
      <div class="mb-2">
        <a href="#" class="hover:text-gray-900">Terms of use</a> |
        <a href="#" class="hover:text-gray-900">Privacy policy</a>
      </div>
      <div>
        <p>Copyright © 2024 Elluxion Travel Singapore PTE. LTD. All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const activeNames = ref([])

const aboutLinks = [
  { text: 'About us', url: 'https://corporate.azgotrip.com/about-6' },
  { text: 'Careers', url: 'https://corporate.azgotrip.com/contact' },
  { text: 'Partnerships', url: 'https://corporate.azgotrip.com/general-4' },
  { text: 'Products', url: 'https://corporate.azgotrip.com/about' },
  { text: 'Press', url: 'https://corporate.azgotrip.com/press-media-coverage' },
  { text: 'Campaigns', url: 'https://corporate.azgotrip.com/' },
]

const addresses = [
  {
    city: 'Singapore',
    details: 'The Executive Centre, Level 42,<br />Six Battery Road',
  },
  {
    city: 'Hong Kong',
    details: 'One ifc, Level 20, No.1 Harbour<br />View Street Central',
  },
  {
    city: 'Taipei',
    details: 'Taipei 101 Tower, Level 57, No.7, Section 5 Xinyi Road, Xinyi District',
  },
]
</script>

<style lang="scss">
.az-collapse {
  .van-cell {
    padding: 10px 0;
  }

  .van-collapse-item {
    &__content {
      padding: 0 0 12px 0;
    }

    &__title--expanded:after {
      display: none;
    }

    &--border:after {
      left: 0;
      right: 0;
    }
  }
}
</style>
